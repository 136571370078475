/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
	getReferralTransactionsList,
	getConvertsTransactionsList,
	getCryptoTransactionsList,
	getFiatTransactionsList,
	getTransfersTransactionsList,
	getWithdrawalsTransactionsList,
} from 'redux/reducers/transactions/selectors';
import Pagination from 'ui/Pagination';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import {
	getReferralTransactionsRequest,
	getConvertsTransactionsRequest,
	getCryptoTransactionsRequest,
	getFiatTransactionsRequest,
	getTransfersTransactionsRequest,
	getWithdrawalsTransactionsFiatRequest,
	getWithdrawalsTransactionsRequest,
} from 'redux/reducers/transactions/reducer';
import {
	ICryptoTransactionsRequestPayload,
	IFiatTransactionsRequestPayload,
	ITransactionsRequestPayload,
	TTypeTransactions,
} from 'redux/reducers/transactions/types';
import SearchBar from 'components/SearchBar';
import {
	CRYPTO_TRANSACTION_STATUS_ARRAY,
	FIAT_TRANSACTION_STATUS_ARRAY,
	TRANSFERS_FROM_TO_WALLET_ARRAY,
	TYPE_TRANSACTIONS_CONVERTS,
	TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_CONVERTS_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_REFERRAL,
	TYPE_TRANSACTIONS_REFERRAL_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_REFERRAL_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_CRYPTO,
	TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_FIAT,
	TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_FIAT_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_TRANSFER,
	TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_TRANSFER_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_WITHDRAWALS,
	TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO,
	TYPE_TRANSACTIONS_WITHDRAWALS_FIAT,
	TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_TEXT_ARRAY,
	WITHDRAWALS_TRANSACTION_STATUS_ARRAY,
} from 'redux/reducers/transactions/constants';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { ExportIcon, ClockIcon } from 'assets/img/icons';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { useDateTimePicker } from 'services/hooks/useDateTimePicker';
import { Deposit } from './Deposit';
import { Transfers } from './Transfers';
import { Converts } from './Converts';
import { Referral } from './Referral';
import 'rsuite/dist/rsuite.min.css';
import { WithdrawalsCryptoFiat } from './WithdrawalsCryptoFiat';

export const Transactions: FC = () => {
	const crypto = useSelector(getCryptoTransactionsList);
	const fiat = useSelector(getFiatTransactionsList);
	const withdrawals = useSelector(getWithdrawalsTransactionsList);
	const transfers = useSelector(getTransfersTransactionsList);
	const converts = useSelector(getConvertsTransactionsList);
	const referral = useSelector(getReferralTransactionsList);
	// const [lastPage, setLastPage] = useState(0);
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const [tabIndex, setTabIndex] = useState(0);
	const [startDate, setStartDate] = useState<string | null>(null);
	const [endDate, setEndDate] = useState<string | null>(null);
	const dispatch = useDispatch();

	const { checkReadable } = usePermission();

	const transfersReadAccess =
		checkReadable(permissions.MANAGE_CRYPTO_TRANSFERS) ||
		checkReadable(permissions.MANAGE_FIAT_BANK_TRANSFERS);

	const { afterToday } = DateRangePicker;

	const onDateSelect = (_startDate: string | null, _endDate: string | null) => {
		setStartDate(_startDate);
		setEndDate(_endDate);
	};

	const { dateRangeValue, timeRangeValue, changeDateRangeHandler, changeTimeRangeHandler } =
		useDateTimePicker({ onDateSelect });

	const now = new Date();
	const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

	const [typeTransactions, setTypeTransactions] =
		useState<TTypeTransactions>(TYPE_TRANSACTIONS_CRYPTO);

	const [dropdownArray, setDropdownArray] = useState(CRYPTO_TRANSACTION_STATUS_ARRAY);

	const [searchFieldArray, setSearchFieldArray] = useState(TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY);
	const [searchFieldTextArray, setSearchFieldTextArray] = useState(
		TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY,
	);

	const { page, text, field, tab } = queryString.parse(search);
	const [searchField, setSearchField] = useState(searchFieldArray[0]);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const getRequest = (
		params: ICryptoTransactionsRequestPayload | IFiatTransactionsRequestPayload,
		typeTransactionsParam?: TTypeTransactions,
	) => {
		const type = typeTransactionsParam || typeTransactions;

		switch (type) {
			case TYPE_TRANSACTIONS_CRYPTO:
				dispatch(getCryptoTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_FIAT:
				dispatch(getFiatTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO:
				dispatch(getWithdrawalsTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_WITHDRAWALS_FIAT:
				dispatch(getWithdrawalsTransactionsFiatRequest(params));
				break;
			case TYPE_TRANSACTIONS_TRANSFER:
				dispatch(getTransfersTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_CONVERTS:
				dispatch(getConvertsTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_REFERRAL:
				dispatch(getReferralTransactionsRequest(params));
				break;

			default:
				dispatch(getCryptoTransactionsRequest(params));
		}
	};
	const lastPage = () => {
		if (typeTransactions === TYPE_TRANSACTIONS_CRYPTO) return crypto?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_FIAT) return fiat?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO)
			return withdrawals?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_WITHDRAWALS_FIAT) return withdrawals?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_TRANSFER) return transfers?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_CONVERTS) return converts?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_REFERRAL) return referral?.last_page ?? 0;

		return 0;
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = typeof text === 'string' ? text : String(text);
		}

		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: ``;

		const params: ITransactionsRequestPayload = { current_page: pageNumber || 1 };
		if (verifyText.length > 0) {
			params.search_value = verifyText;
			params.search_field = String(searchField);
		}
		params.start_date = startDate || undefined;
		params.end_date = endDate || undefined;

		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		getRequest(params);
	};

	const handleSearch = (txt: string) => {
		const params: ITransactionsRequestPayload = {};
		if (txt.length >= 1) {
			params.search_value = txt;
			params.search_field = String(searchField);
		}
		params.start_date = startDate || undefined;
		params.end_date = endDate || undefined;

		history.push({
			pathname: location.pathname,
			search: txt.length >= 1 ? `?text=${txt}&field=${searchField}` : ``,
		});
		setCurrentPage(1);

		if ((searchField === 'currency' || searchField === 'code') && txt !== '' && txt.length < 3)
			return;
		getRequest(params);
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 600);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		if (term) {
			handleSearch(term);
		}
	}, [term, searchField]);

	useEffect(() => {
		if (typeTransactions === TYPE_TRANSACTIONS_CRYPTO) {
			dispatch(getCryptoTransactionsRequest({}));
		}
		if (typeTransactions === TYPE_TRANSACTIONS_FIAT) {
			dispatch(getFiatTransactionsRequest({}));
		}
		if (typeTransactions === TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO) {
			dispatch(getWithdrawalsTransactionsRequest({}));
		}
		if (typeTransactions === TYPE_TRANSACTIONS_WITHDRAWALS_FIAT) {
			dispatch(getWithdrawalsTransactionsRequest({}));
		}
		if (typeTransactions === TYPE_TRANSACTIONS_TRANSFER) {
			dispatch(getTransfersTransactionsRequest({}));
		}
		if (typeTransactions === TYPE_TRANSACTIONS_CONVERTS) {
			dispatch(getConvertsTransactionsRequest({}));
		}
		if (typeTransactions === TYPE_TRANSACTIONS_REFERRAL) {
			dispatch(getReferralTransactionsRequest({}));
		}
	}, [typeTransactions]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const setSearchFieldArrayHandler = (type: TTypeTransactions) => {
		let searchParams = '';
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		changeDateRangeHandler(null);
		changeTimeRangeHandler(null);
		onDateSelect(null, null);
		switch (type) {
			case TYPE_TRANSACTIONS_CRYPTO:
				setSearchFieldArray(TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY[0]);
				setDropdownArray(CRYPTO_TRANSACTION_STATUS_ARRAY);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_CRYPTO}`
				}`;
				break;
			case TYPE_TRANSACTIONS_FIAT:
				setSearchFieldArray(TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_FIAT_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY[0]);
				setDropdownArray(FIAT_TRANSACTION_STATUS_ARRAY);

				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_FIAT}`
				}`;
				break;
			case TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO:
				setSearchFieldArray(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY[0]);
				setDropdownArray(WITHDRAWALS_TRANSACTION_STATUS_ARRAY);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_WITHDRAWALS}`
				}`;
				break;
			case TYPE_TRANSACTIONS_WITHDRAWALS_FIAT:
				setSearchFieldArray(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY[0]);
				setDropdownArray(WITHDRAWALS_TRANSACTION_STATUS_ARRAY);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_WITHDRAWALS}`
				}`;
				break;
			case TYPE_TRANSACTIONS_TRANSFER:
				setSearchFieldArray(TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_TRANSFER_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY[0]);
				setDropdownArray(TRANSFERS_FROM_TO_WALLET_ARRAY);

				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_TRANSFER}`
				}`;
				break;
			case TYPE_TRANSACTIONS_CONVERTS:
				setSearchFieldArray(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_CONVERTS_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_CONVERTS}`
				}`;
				break;
			case TYPE_TRANSACTIONS_REFERRAL:
				setSearchFieldArray(TYPE_TRANSACTIONS_REFERRAL_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_REFERRAL_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_REFERRAL_SEARCH_ARRAY[0]);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_REFERRAL_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_REFERRAL}`
				}`;
				break;
			default:
				setSearchFieldArray(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_CONVERTS}`
				}`;
		}
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
	};

	const setTypeTransactionsHandler = (type: TTypeTransactions) => {
		setTypeTransactions(type);
		history.push({
			pathname: location.pathname,
			search: `?tab=${type}`,
		});
		setSearchFieldArrayHandler(type);
		handleClearSearch();
		setCurrentPage(1);
		getRequest({}, type);
	};

	const exportHandler = () => {
		const params: ITransactionsRequestPayload = {};
		params.current_page = currentPage;
		if ((debouncedTerm?.length ?? 0) > 0) {
			params.search_value = debouncedTerm;
			params.search_field = searchField;
		}
		params.start_date = startDate || undefined;
		params.end_date = endDate || undefined;
		params.export = 1;
		getRequest(params);
	};

	useEffect(() => {
		if (!term && !debouncedTerm) {
			history.push(location.pathname);
			const params: ITransactionsRequestPayload = dateRangeValue
				? {
						current_page: 1,
						start_date: startDate || undefined,
						end_date: endDate || undefined,
				  }
				: {};
			getRequest(params, typeTransactions);
		}
	}, [term, debouncedTerm]);

	useEffect(() => {
		const param: ITransactionsRequestPayload = {
			current_page: 1,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};
		if ((text?.length ?? 0) > 0) {
			param.search_field = searchField;
			param.search_value = String(text);
		}
		getRequest(param);
		history.push({
			pathname: location.pathname,
			search: (text?.length ?? 0) > 0 ? `?text=${String(text)}&field=${searchField}` : ``,
		});
		setCurrentPage(1);
	}, [startDate, endDate]);

	return (
		<>
			<div className="title-block">
				<p className="title">Transactions</p>
			</div>
			<div className="count-label count-label--user-management count-label--left transactions-data-picker">
				<SearchBar
					setDebouncedTerm={setDebouncedTerm}
					debouncedTerm={debouncedTerm}
					searchFieldArray={searchFieldArray}
					searchFieldTextArray={searchFieldTextArray}
					searchField={searchField}
					setSearchField={setSearchField}
					handleClearSearch={handleClearSearch}
					typeSearch={
						searchField === 'status' || searchField === 'from' || searchField === 'to'
							? 'dropdown'
							: undefined
					}
					dropdownArray={dropdownArray}
					tab={typeTransactions}
				/>
				<DateRangePicker
					format="dd/MM/yyyy"
					size="lg"
					block
					placement="bottomEnd"
					style={{ width: '308px' }}
					disabledDate={afterToday?.()}
					value={dateRangeValue}
					onChange={changeDateRangeHandler}
					defaultCalendarValue={[prevMonth, now]}
					ranges={[]}
				/>

				<DateRangePicker
					className="time-datepicker"
					format="HH:mm"
					size="lg"
					block
					placement="bottomEnd"
					style={{ width: '200px' }}
					value={timeRangeValue}
					onChange={changeTimeRangeHandler}
					defaultCalendarValue={[prevMonth, now]}
					ranges={[]}
					caretAs={ClockIcon}
				/>

				<button type="button" className="transaction-export-btn" onClick={exportHandler}>
					Export transaction history
					<span className="transaction-export-btn__icon">
						<ExportIcon />
					</span>
				</button>
			</div>

			<Tabs
				className="settings-tabs add_some"
				selectedIndex={tabIndex}
				onSelect={(index) => {
					setTabIndex(index);
					if (index === 0) setTypeTransactionsHandler(TYPE_TRANSACTIONS_CRYPTO);
					else if (index === 1) setTypeTransactionsHandler(TYPE_TRANSACTIONS_WITHDRAWALS_CRYPTO);
					else if (index === 2) setTypeTransactionsHandler(TYPE_TRANSACTIONS_TRANSFER);
					else if (index === 3) setTypeTransactionsHandler(TYPE_TRANSACTIONS_CONVERTS);
					else if (index === 4) setTypeTransactionsHandler(TYPE_TRANSACTIONS_REFERRAL);
					else if (index === 5) setTypeTransactionsHandler(TYPE_TRANSACTIONS_WITHDRAWALS_FIAT);
				}}
			>
				<TabList className="user-settings user-settings--settings">
					{transfersReadAccess && (
						<>
							<Tab className="user-settings__item">
								<span className="user-settings__link">Deposit</span>
							</Tab>
							<Tab className="user-settings__item">
								<span className="user-settings__link">Withdrawal</span>
							</Tab>
						</>
					)}
					{checkReadable(permissions.MANAGE_CRYPTO_TRANSFERS) && (
						<Tab className="user-settings__item">
							<span className="user-settings__link">Transfer</span>
						</Tab>
					)}
					<Tab className="user-settings__item">
						<span className="user-settings__link">Convert</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Referral</span>
					</Tab>
				</TabList>
				{transfersReadAccess && (
					<>
						<TabPanel>
							<Deposit setTypeTransactions={setTypeTransactionsHandler} />
						</TabPanel>
						<TabPanel>
							<WithdrawalsCryptoFiat setTypeTransactions={setTypeTransactionsHandler} />
						</TabPanel>
					</>
				)}
				{checkReadable(permissions.MANAGE_CRYPTO_TRANSFERS) && (
					<TabPanel>
						<Transfers />
					</TabPanel>
				)}
				<TabPanel>
					<Converts />
				</TabPanel>
				<TabPanel>
					<Referral />
				</TabPanel>
			</Tabs>
			{(lastPage() ?? 0) > 1 && (
				<Pagination
					pageCount={lastPage() ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};
