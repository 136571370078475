export const TWO_FA_STATUS_REJECT = 'Reject';
export const TWO_FA_STATUS_NEW = 'New';
export const TWO_FA_STATUS_ACCEPT = 'Accept';

export const TWO_FA_STATUS_NUM_REJECT = 0;
export const TWO_FA_STATUS_NUM_NEW = 1;
export const TWO_FA_STATUS_NUM_ACCEPT = 2;

export const TWO_FA_STATUS_REJECT_CLASS = 'status--error';
export const TWO_FA_STATUS_NEW_CLASS = 'status--verified';
export const TWO_FA_STATUS_ACCEPT_CLASS = 'status--inprogres';

export const USER_STATUS_APPROVED = 'approved';
export const USER_STATUS_PENDING = 'pending';
export const USER_STATUS_UNVERIFIED = 'unverified';
export const USER_STATUS_REJECTED = 'rejected';
export const USER_STATUS_ON_HOLD = 'on_hold';

export const USER_LEVEL_VIP = 'vip';
export const USER_LEVEL_REGULAR = 'regular';

export const USER_STATUS_NUM_APPROVED = '2';
export const USER_STATUS_NUM_PENDING = '3';
export const USER_STATUS_NUM_UNVERIFIED = '1';
export const USER_STATUS_NUM_REJECTED = '4';
export const USER_STATUS_NUM_ON_HOLD = '5';

export const USER_STATUS_APPROVED_CLASS = 'status--verified';
export const USER_STATUS_PENDING_CLASS = 'status--inprogres';
export const USER_STATUS_UNVERIFIED_CLASS = 'status--unverified';
export const USER_STATUS_REJECTED_CLASS = 'status--error';
export const USER_STATUS_ON_HOLD_CLASS = 'status--on-hold';

// ==========================

export const WHITELISTING_IP_STATUS_WHITELISTING = 1;
export const WHITELISTING_IP_STATUS_NOT_VERIFIED = 0;
export const WHITELISTING_IP_STATUS_ALL = 2;

export const WHITELISTING_IP_STATUS_WHITELISTING_TEXT = 'In Whitelisting';
export const WHITELISTING_IP_STATUS_NOT_VERIFIED_TEXT = 'Not Verified';
export const WHITELISTING_IP_STATUS_ALL_TEXT = 'All';

export type TWhitelistingIPStatus = {
	statusText: string;
	statusClass: string;
};
export const whitelistingIPStatus: TWhitelistingIPStatus[] = [
	{
		statusText: WHITELISTING_IP_STATUS_NOT_VERIFIED_TEXT,
		statusClass: 'status--error',
	},
	{
		statusText: WHITELISTING_IP_STATUS_WHITELISTING_TEXT,
		statusClass: 'status--verified',
	},
	{
		statusText: WHITELISTING_IP_STATUS_ALL_TEXT,
		statusClass: '',
	},
];

export type TWhitelistingIPStatusNum = {
	[WHITELISTING_IP_STATUS_WHITELISTING_TEXT]: number;
	[WHITELISTING_IP_STATUS_NOT_VERIFIED_TEXT]: number;
	[WHITELISTING_IP_STATUS_ALL_TEXT]: number;
};

export const whitelistingIPStatusNum: TWhitelistingIPStatusNum = {
	[WHITELISTING_IP_STATUS_WHITELISTING_TEXT]: WHITELISTING_IP_STATUS_WHITELISTING,
	[WHITELISTING_IP_STATUS_NOT_VERIFIED_TEXT]: WHITELISTING_IP_STATUS_NOT_VERIFIED,
	[WHITELISTING_IP_STATUS_ALL_TEXT]: WHITELISTING_IP_STATUS_ALL,
};

/// ==========================

export const DEPOSIT_AND_WITHDRAW_HISTORY_TYPE_WITHDRAWAL = 'withdrawal';
export const DEPOSIT_AND_WITHDRAW_HISTORY_TYPE_DEPOSIT = 'deposit';

export const DEPOSIT_AND_WITHDRAW_HISTORY_TYPE_BALANCES = 'Balances';
export const DEPOSIT_AND_WITHDRAW_HISTORY_TYPE_ORDER_HISTORY = 'Orders history';

export const MENU_MARGIN_BALANCES_TYPE = 'Balances';
export const MENU_MARGIN_ORDERS_HISTORY_TYPE = 'Orders history';
export const MENU_MARGIN_BORROWING_TYPE = 'Borrowing';
export const MENU_MARGIN_REPAYMENT_TYPE = 'Repayment';
export const MENU_MARGIN_INTEREST_TYPE = 'Interest';
export const MENU_MARGIN_MARGIN_CALLS_TYPE = 'Margin calls';
export const MENU_MARGIN_LIQUIDATION_HISTORY_TYPE = 'Liquidation history';

export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_REJECTED = 'rejected';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PROCESSED = 'processed';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PENDING = 'pending';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_IN_PROGRESS = 'in progress';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_UNCONFIRMED = 'unconfirmed';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_CONFIRMED = 'confirmed';

export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_REJECTED_TEXT = 'Rejected';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PROCESSED_TEXT = 'Processed';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PENDING_TEXT = 'Pending';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_IN_PROGRESS_TEXT = 'In progress';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_UNCONFIRMED_TEXT = 'Unconfirmed';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_CONFIRMED_TEXT = 'Confirmed';

export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_REJECTED_CLASS = 'status--error';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PROCESSED_CLASS = 'status--unverified';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PENDING_CLASS = 'status--inprogres';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_IN_PROGRESS_CLASS = 'status--inprogress';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_UNCONFIRMED_CLASS = 'status--error';
export const DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_CONFIRMED_CLASS = 'status--verified';

export type TDepositAndWithdrawHistoryStatusObj = { statusText: string; statusClass: string };

export const depositAndWithdrawHistoryStatus = {
	[DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_REJECTED]: {
		statusText: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_REJECTED_TEXT,
		statusClass: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_REJECTED_CLASS,
	},
	[DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PROCESSED]: {
		statusText: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PROCESSED_TEXT,
		statusClass: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PROCESSED_CLASS,
	},
	[DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PENDING]: {
		statusText: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PENDING_TEXT,
		statusClass: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_PENDING_CLASS,
	},
	[DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_IN_PROGRESS]: {
		statusText: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_IN_PROGRESS_TEXT,
		statusClass: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_IN_PROGRESS_CLASS,
	},
	[DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_UNCONFIRMED]: {
		statusText: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_UNCONFIRMED_TEXT,
		statusClass: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_UNCONFIRMED_CLASS,
	},
	[DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_CONFIRMED]: {
		statusText: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_CONFIRMED_TEXT,
		statusClass: DEPOSIT_AND_WITHDRAW_HISTORY_STATUS_CONFIRMED_CLASS,
	},
};

export const USER_STATUS_ARRAY = [
	'all',
	USER_STATUS_NUM_APPROVED,
	USER_STATUS_NUM_PENDING,
	USER_STATUS_NUM_UNVERIFIED,
	USER_STATUS_NUM_REJECTED,
	USER_STATUS_NUM_ON_HOLD,
];
export const USER_STATUS_TEXT_ARRAY = [
	'All',
	USER_STATUS_APPROVED,
	USER_STATUS_PENDING,
	USER_STATUS_UNVERIFIED,
	USER_STATUS_REJECTED,
	USER_STATUS_ON_HOLD,
];

export const USER_LEVEL_ARRAY = ['all', USER_LEVEL_REGULAR, USER_LEVEL_VIP];

export const USER_SEARCH_ARRAY = [
	'user_id',
	'status',
	'email',
	'level',
	'p2p_nickname',
	'kyc_approved',
	'all',
];
export const USER_SEARCH_TEXT_ARRAY = [
	'User ID',
	'Status',
	'E-mail',
	'Level',
	'P2P Nickname',
	'Verification',
	'Registration',
];
