import { useState } from 'react';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';

interface IParams {
	onDateSelect: (startDate: string | null, endDate: string | null) => void;
}

const timeRegex = /^(\d{2}:\d{2}):/;

const getDateStr = (date: Date) => {
	return date.toISOString().split('T')[0] || null;
};

const getTimeStr = (date: Date) => {
	return timeRegex.exec(date.toISOString().split('T')?.[1])?.[1] || null;
};

export const useDateTimePicker = ({ onDateSelect }: IParams) => {
	const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);
	const [timeRangeValue, setTimeRangeValue] = useState<DateRange | null>(null);

	const changeDateRangeHandler = (d: DateRange | null) => {
		const startDate = d?.[0] ? getDateStr(d[0]) : null;
		const endDate = d?.[1] ? getDateStr(d[1]) : null;
		setDateRangeValue(d);
		setTimeRangeValue(null);
		onDateSelect(startDate ? `${startDate} 00:00` : null, endDate ? `${endDate} 23:59` : null);
	};

	const changeTimeRangeHandler = (d: DateRange | null) => {
		const startDate = dateRangeValue?.[0] ? getDateStr(dateRangeValue[0]) : getDateStr(new Date());
		const endDate = dateRangeValue?.[1] ? getDateStr(dateRangeValue[1]) : getDateStr(new Date());
		const startTime = d?.[0] ? getTimeStr(d[0]) : null;
		const endTime = d?.[1] ? getTimeStr(d[1]) : null;
		setTimeRangeValue(d);
		if (startDate && endDate) {
			if (startTime && endTime) {
				onDateSelect(`${startDate} ${startTime}`, `${endDate} ${endTime}`);
				return;
			}
			onDateSelect(`${startDate} 00:00`, `${endDate} 23:59`);
			return;
		}
		onDateSelect(null, null);
	};

	return {
		dateRangeValue,
		timeRangeValue,
		changeDateRangeHandler,
		changeTimeRangeHandler,
	};
};
